.home .hero_container {
    /* background-color: #E8EAF5; */
}

.home .hero {
    background: url('../img/bg.jpg');
    background-position: center;
    background-color: #E8EAF5;
    background-repeat: no-repeat;
font-size: 20px;
    min-height: 88.5vh;
    display: flex;
    width: 100%;
    /* align-items: center; */
    /* justify-content: space-between; */
    align-items: flex-start;
    padding: 0 200px;
}

.home .hero_title-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    margin-right: 100px;
}


.home .hero_title {
    font-size: 64px;
    font-weight: 400;
    text-align: left;

}
.home .hero_title-bold{
    font-size: 96px;
    font-weight: 600;
    text-align: left;
    margin-top: -25px;

}

.home .hero_subtitle {
    text-align: left;
}

.home .hero_img {
    /* align-self: flex-end; */
    /* margin-bottom: 80px; */
    margin-top: 170px;
}

.home .hero button {
    background-color: #468E4B;
    color: #ffffff;
    font-size: 16px;
    border-radius: 15px;
    padding: 14px 36px;
    width: fit-content;
    margin-top: 27px;

}

.home .partners {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 200px;
}

.home .partners > div {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
}

.home .partners .img1{
    width: 200px;
    height: auto;
}
.home .partners .img2{
    width: 100px;
    height: auto;
}
.home .partners .img3{
    width: 100px;
    height: auto;
}

.home .who {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 0 200px;
    margin-top: 140px;
    margin-bottom: 100px;
}

.home .who_main {
    text-align: left;
    font-weight: 500;
    margin-right: 90px;
}

.home .who_main-title {
    font-size: 36px;
    font-weight: 700;
}

.home .who_flex {
    display: flex;
    align-items: center;
}

.home .who_box {
    width: 252px;
    min-height: 330px;
    background: #FFFFFF;
box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1);
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
text-align: left;
padding: 20px;
}

.home .mr30 {
    margin-right: 30px;
}



.home .who_box svg {
    margin-bottom: 20px;
}

.home .who_box-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}
.home .who_box-subtitle {
    font-size: 14px;
}

.home .us {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 40px 200px;
    margin-top: 140px;
    margin-bottom: 100px;
    background-color: #E8EAF5;
}

.home .us_main {
    text-align: left;
    font-weight: 500;
    margin-right: 130px;
}

.home .us_main-title {
    font-size: 36px;
    font-weight: 700;
}

.home .us_main img {
    width: 358px;
    margin-top: 30px;
}

.home .us_num img {
    width: 35px;
    margin-right: 45px;
}
.home .us_num .img {
    width: 24px;
    margin-right: 45px;
}

.home .us_num > div {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 600;
}

.home .events {
    padding: 0 200px;
}

.home .events_title {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 700;
}

.home .events_box {
    display: flex;
    align-items: center;
    background-color: #E8EAF5;
    border-radius: 20px;
    width: 100%;
    justify-content: space-between;
    padding-right: 40px;
    margin-bottom: 30px;
}

.home .events_box img {
    width: 352px;
    border-radius: 20px;
}

.home .event_status{
    margin-left: 30px;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.home .event_status-title{
   background-color: #FE659C;
   color: #ffffff;
   border-radius: 30px;
   padding: 4px 16px;
   margin-bottom: 5px;
   font-size: 16px;
}

.home .event_date {
    text-align: left;
    margin-right: 80px;
    /* font-size: 16px; */
}

.home .event_time {
    text-align: left;
}
.home .event_date-title, .event_time-title {
    font-weight: 600;
    color: #9e9e9e;
}

.home .crew {
    padding: 80px 200px;
    margin-bottom: 100px;
}

.home .crew_title {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 700;
}

.home .crew_flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.home .crew_box {
    width: 30%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.home .crew_box img {
    width: 100%;
}

.home .crew_box-inner {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    text-align: left;
    color: #c3c3c3;
    border-radius: 10px;
    margin-top: -20px;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.15);
    min-height: 132px;

}

.home .crew_box-inner-title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}

.home .crew_box-inner-subtitle {
    margin-top: 10px;
}


.home .review {
    padding: 60px 20px;
}

.home .review_slide {
    /* display: flex;
    align-items: flex-start; */
    width: 100%;
    /* justify-content: space-between; */
    background-color: #ffffff;
    right: 0;
    /* float: right; */
    /* margin-bottom: 60px; */
}

.home .review_title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 70px;
}

.home .review_card {
    width: 600px;
    padding: 20px;
    min-height: 260px;
    background-color: #ffffff;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
border-radius: 10px;
text-align: start;
font-size: 20px;
margin-right: 49px;
}

.home .review_card-flex {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.home .review_card-flex .img {
    margin-right: 25px;
    height: 108px;
    width: 108px;
    border: 50%;
    /* display: none; */
}

/* .home .review_card-flex > div {
    text-align: start;
} */

.home .card_flex-title {
    font-weight: 600;
}
.home .card_flex-subtitle {
    font-weight: 500;
    color: #9C9C9C;
}


@media (max-width: 1170px) {
    .home .hero, .home .who, .home .events {
        padding: 0 40px;
    }

    .home .partners, .home .us { 
        padding: 40px;
    }

    .home .crew {
        padding: 80px 40px;
    }
    .home .hero {
        /* height: auto; */
        padding: 20px;
    }
}

@media (max-width: 874px) {
    .home .who {
        flex-direction: column;
    }

    .home .who_main {
        margin-bottom: 40px;
    }


    .home .events_box {
        flex-direction: column;
        align-items: flex-start;
    }

    .home .event_date, .home .event_time {
        margin: 20px;
    }

    .home .hero {
        flex-direction: column;
    }
    
}

@media (max-width: 640px) {
    

    

   
    .home .flex_column {
        flex-direction: column !important;
        /* background-color: yellow !important; */
        width: 100% !important;
        align-items: flex-start;
    }

    .home .events_box {
        padding-right: 0;
    }

    .home .flex_column .img {
        width: 100% !important;
    }
    
    .home .event_status {
        margin-top: 20px;
        margin-left: 20px;
    }

    .home .crew_flex {
        flex-direction: column;
    }

    .home .crew_box {
        width: 100%;
        margin-bottom: 40px;
    }

    .home .partners {
        flex-wrap: wrap;
    }

    .home .partners > div{
        /* background-color: yellow !important; */
        width: 100% ;
        margin-bottom: 10px;
    }

    

    .home .who_box {
        width: 100%;
        margin-bottom: 20px;
    }

    .home .who_box svg {
        margin-bottom: 40px;
    }

    .home .us {
        flex-direction: column;
        align-items: flex-start;
    }

    .home .us_num {
        margin-top: 20px;
    }

    .home .review_card {
        font-size: 14px;
        width: 300px;
    }

    .home .hero_title {
        font-size: 50px;
       
    
    }
    .home .hero_title-bold{
        font-size: 60px;
    }

    .home .hero_img {
        margin-top: 100px;
    }

    .home .hero_title-container {
        margin-top: 50px;
    }

    .home .who_main {
        /* background-color: yellow; */
        width: 100%;
        margin: auto;
        margin-bottom: 20px;
    }

    .home .us_main {
        width: 100%;
    }

    .home .us_num > div {
        font-size: 16px !important;
    }

    .home .us_num img {
        width: 25px;
    }
    .home .us_num .img {
        width: 14px;
    }

    .home .event_status-title {
        font-size: 14px;
    }

    .home .event_status, .home .event_date, .home .event_time{
        font-size: 14px;
    }


    .home .who {
        margin-top: 20px;
    }
 
}


